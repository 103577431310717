<template>
  <div class="student-dashboard">
    <div v-if="showDashboard" class="mt-33 flex dashboard">
      <!-- Subject Section -->
      <div class="subject-section">
        <div class="subject-sec">
          <h1 class="fs-18 fw-700 dark-text top-header">Subjects</h1>
          <!-- Subjects -->
          <div class="subject-content">
            <div
              class="subject-box relative card-radius card-shadow"
              v-for="subject in getNewStudentDashboardsub"
              :key="subject.subjectId"
            >
              <h2 class="purple-text fs-15 fw-700 relative sub-name">
                {{
                  subject.subjectName.length > 12
                    ? subject.subjectName.slice(0, 12) + ".."
                    : subject.subjectName
                }}
              </h2>
              <div v-if="Object.keys(subject.subjectMetrics).length > 0">
                <h3
                  class="fs-18 fw-700 flex accuracy"
                  :style="`color: ${subject.subjectMetrics.Accuracy.color} !important;`"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style="margin-right: 7px"
                  >
                    <path
                      d="M14.75 7.25H13.925C13.55 4.55 11.45 2.375 8.75 2.075V1.25C8.75 0.8 8.45 0.5 8 0.5C7.55 0.5 7.25 0.8 7.25 1.25V2.075C4.55 2.375 2.375 4.55 2.075 7.25H1.25C0.8 7.25 0.5 7.55 0.5 8C0.5 8.45 0.8 8.75 1.25 8.75H2.075C2.45 11.45 4.55 13.625 7.25 13.925V14.75C7.25 15.2 7.55 15.5 8 15.5C8.45 15.5 8.75 15.2 8.75 14.75V13.925C11.45 13.55 13.625 11.45 13.925 8.75H14.75C15.2 8.75 15.5 8.45 15.5 8C15.5 7.55 15.2 7.25 14.75 7.25ZM8 12.5C5.525 12.5 3.5 10.475 3.5 8C3.5 5.525 5.525 3.5 8 3.5C10.475 3.5 12.5 5.525 12.5 8C12.5 10.475 10.475 12.5 8 12.5ZM8 5.75C6.725 5.75 5.75 6.725 5.75 8C5.75 9.275 6.725 10.25 8 10.25C9.275 10.25 10.25 9.275 10.25 8C10.25 6.725 9.275 5.75 8 5.75Z"
                      :fill="subject.subjectMetrics.Accuracy.color"
                    />
                  </svg>
                  <span>{{ subject.subjectMetrics.Accuracy.value }}</span>
                </h3>
                <div class="flex">
                  <h3
                    class="fs-12 fw-700 flex items-center"
                    :style="`color: ${subject.subjectMetrics.Attempted.color} !important; margin-right: 12px;`"
                    v-if="Object.keys(subject.subjectMetrics.Attempted).length > 0"
                  >
                    <img
                      :src="require(`../../assets/target (1) 2.svg`)"
                      alt=""
                      style="margin-right: 7px"
                    />
                    <span>{{ subject.subjectMetrics.Attempted.value }}</span>
                  </h3>
                  <h3
                    class="fs-12 fw-700 flex items-center"
                    :style="`color: ${subject.subjectMetrics.AvgTime.color}`"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style="margin-right: 7px"
                    >
                      <path
                        d="M9.00078 16.0312C12.4602 16.0312 15.2727 13.2047 15.2727 9.73125C15.2727 8.19844 14.7242 6.77812 13.8102 5.69531L14.1898 5.31562C14.4148 5.09062 14.4148 4.73906 14.1898 4.51406C13.9648 4.28906 13.6133 4.28906 13.3883 4.51406L13.0086 4.89375C12.0664 4.07812 10.8711 3.55781 9.56328 3.44531V3.09375H9.78828C10.0977 3.09375 10.3508 2.84062 10.3508 2.53125C10.3508 2.22187 10.0977 1.96875 9.78828 1.96875H8.21328C7.90391 1.96875 7.65078 2.22187 7.65078 2.53125C7.65078 2.84062 7.90391 3.09375 8.21328 3.09375H8.43828V3.44531C7.13047 3.55781 5.93516 4.07812 4.97891 4.89375L4.59922 4.5C4.37422 4.275 4.02266 4.275 3.79766 4.5C3.57266 4.725 3.57266 5.07656 3.79766 5.30156L4.17734 5.68125C3.26328 6.77813 2.71484 8.18437 2.71484 9.71719C2.72891 13.2047 5.54141 16.0312 9.00078 16.0312ZM9.00078 4.26094C11.9961 4.26094 14.4289 6.70781 14.4289 9.73125C14.4289 12.7547 11.9961 15.1875 9.00078 15.1875C6.00547 15.1875 3.57266 12.7406 3.57266 9.73125C3.57266 6.72187 6.00547 4.26094 9.00078 4.26094Z"
                        :fill="subject.subjectMetrics.AvgTime.color"
                      />
                      <path
                        d="M8.99922 13.9355C11.3195 13.9355 13.1898 12.0512 13.1898 9.7168C13.1898 7.38242 11.3055 5.49805 8.99922 5.49805C6.69297 5.49805 4.80859 7.38242 4.80859 9.7168C4.80859 12.0512 6.67891 13.9355 8.99922 13.9355ZM8.57734 8.73242V6.73555C8.57734 6.49648 8.76016 6.31367 8.99922 6.31367C9.23828 6.31367 9.42109 6.49648 9.42109 6.73555V8.73242C9.80078 8.90117 10.068 9.28086 10.068 9.7168C10.068 10.3074 9.58984 10.7855 8.99922 10.7855C8.40859 10.7855 7.93047 10.3074 7.93047 9.7168C7.93047 9.28086 8.19766 8.90117 8.57734 8.73242Z"
                        :fill="subject.subjectMetrics.AvgTime.color"
                      />
                      <line
                        x1="3"
                        y1="9.5"
                        x2="1"
                        y2="9.5"
                        :stroke="subject.subjectMetrics.AvgTime.color"
                        stroke-linecap="round"
                      />
                      <line
                        x1="7.5"
                        y1="15.5"
                        x2="4.5"
                        y2="15.5"
                        :stroke="subject.subjectMetrics.AvgTime.color"
                        stroke-linecap="round"
                      />
                      <line
                        x1="3.5"
                        y1="12.5"
                        x2="2.5"
                        y2="12.5"
                        :stroke="subject.subjectMetrics.AvgTime.color"
                        stroke-linecap="round"
                      />
                      <line
                        x1="1"
                        y1="12.5"
                        x2="0.5"
                        y2="12.5"
                        :stroke="subject.subjectMetrics.AvgTime.color"
                        stroke-linecap="round"
                      />
                      <line
                        x1="3"
                        y1="15.5"
                        x2="2"
                        y2="15.5"
                        :stroke="subject.subjectMetrics.AvgTime.color"
                        stroke-linecap="round"
                      />
                    </svg>

                    <span>{{ subject.subjectMetrics.AvgTime.value }}</span>
                  </h3>
                </div>
              </div>
              <p
                class="fs-10 fw-400"
                style="
                  color: #666666;
                  opacity: 0.7;
                  line-height: 14px;
                  position: relative;
                  top: 28px;
                "
                v-if="Object.keys(subject.subjectMetrics).length === 0"
              >
                Insufficient practice data to analyze your performance.
              </p>
              <img
                class="subject-image"
                :src="subject.subjectIcon ? subject.subjectIcon : randomImage"
              />
              <div class="hover-box absolute yellow-gradient flex flex-between hide">
                <a @click="goPractice(subject)" class="tooltip" data-tooltip="I am a tooltip"
                  ><img :src="require(`../../assets/write 1.png`)" alt="" /><span
                    class="tooltiptext practice"
                    >Practice</span
                  ></a
                >
                <a @click="startSelfTest(subject)" class="tooltip" data-tooltip="I am a tooltip"
                  ><img :src="require(`../../assets/hourglass 2.svg`)" alt="" /><span
                    class="tooltiptext"
                    >Self Test</span
                  ></a
                >
                <a @click="goVideo()" class="tooltip" data-tooltip="I am a tooltip"
                  ><img :src="require(`../../assets/video-icon.svg`)" alt="" /><span
                    class="tooltiptext video"
                    >Video</span
                  ></a
                >
                <a @click="goSubjectCoverage(subject)" class="tooltip" data-tooltip="I am a tooltip"
                  ><img :src="require(`../../assets/dashboard 2.svg`)" alt="" /><span
                    class="tooltiptext video"
                    style="width: 52px; padding: 2px 0"
                    >Coverage</span
                  ></a
                >
              </div>
            </div>
          </div>

          <!-- Parent Request -->
          <div
            class="card-border white-bgc card-sadow card-radius parent-req"
            v-if="showParentCard"
          >
            <h1 class="fs-18 black-text fw-700">Parent Access Request</h1>
            <div class="flex flex-between" style="margin-top: 15px">
              <div>
                <p class="skyblue-text fs-12 fw-700" style="text-transform: capitalize">
                  {{ parentRequest.Name }}
                </p>
                <p class="skyblue-text fs-14 fw-400" style="line-height: 15px">
                  {{ parentRequest.EmailId }}
                </p>
                <div style="margin-top: 10px">
                  <p class="fs-10 fw-400" style="color: gray">Request Date</p>
                  <p class="fs-11 fw-400">{{ parentRequest.Date }}</p>
                </div>
                <div class="flex" style="margin-top: 27px">
                  <a
                    class="yellow-btn"
                    style="line-height: 24px; margin-right: 12px"
                    @click="updateParentRequest(1)"
                    >Accept</a
                  >
                  <a
                    class="yellow-btn skyblue-text"
                    style="background: #f3f5fc !important; line-height: 24px"
                    @click="updateParentRequest(2)"
                    >Reject</a
                  >
                </div>
              </div>
              <img :src="require(`../../assets/parent-req.svg`)" alt="" class="parent-img" />
            </div>
          </div>
          <!-- Events & Highlights -->
          <div v-if="this.avgreport.length > 0" class="new-activity">
            <h1 class="fs-18 fw-700 dark-text top-header">Events & Highlights</h1>
            <!-- <carousel :items="1" :video="true" :nav="false" :navText="['&#8249;', '&#8250;']" :dots="true" :autoplay="true" :autoplayTimeout="10000" :responsive="{0:{items: 1}}" class="flex items-end card-radius"> -->
            <Flicking :options="{ align: 'prev', circular: true }" :plugins="plugins">
              <div v-for="(img, index) in avgreport" :key="index">
                <div
                  v-if="img.CardType === 'AVReport' && img.CardType !== 'LiveClass'"
                  class="slideshow-container flex card-shadow card-radius card-border"
                >
                  <!-- <div class="fade">
                                      <iframe :src="img.VimeoURL" frameborder="0" class="report-video" name="none" allowfullscreen style="position: relative;height: 160px; width: 352px;margin-top: 5px;border-radius: 5px;" />
                                      <div style="padding-left: 33px; padding-top: 5px;">
                                          <h2 class="fs-12 dark-text fw-600">{{ img.ReportName }}</h2>
                                          <p class="fs-10 fw-600">Class: <span class="fw-400">{{ img.ClassName }}</span></p>
                                          <p class="fs-10 fw-600">Subject: <span class="fw-400"></span> {{ img.SubjectName }}</p>
                                      </div>
                                  </div> -->
                  <div class="fade relative" style="padding: 15px; width: 100%">
                    <div class="flex flex-between items-center" style="margin-bottom: 15px">
                      <h2 class="fw-700" style="color: #ffffff !important; font-size: 20px">
                        Subject Coverage Report
                      </h2>
                      <img :src="require(`../../assets/video 2.svg`)" alt="" />
                    </div>
                    <p class="fs-10 fw-400 white-text" style="opacity: 0.7">{{ img.ClassName }}</p>
                    <!-- <p class="fs-15 fw-400" style="color: #FFFFFF !important;margin-top:0px;"> <br><span style="color: white; opacity: 0.9;">Class:</span> {{ img.ClassName }}</p> -->
                    <p class="fs-14 fw-400" style="color: #ffffff !important; margin: 4px 0 5px">
                      {{ img.SubjectName }}
                    </p>
                    <!-- <p class="fs-10 fw-600" style="color: #FFFFFF !important">Faculty: <span class="fw-400"></span>
                                          {{ img.FacultyName }}</p> -->
                    <p class="fs-11 fw-400 white-text" style="margin-top: 11px">
                      Detailed video analysis & recommendations
                    </p>
                    <p class="white-text fs-11 fw-400" style="opacity: 0.7; margin-bottom: 11px">
                      {{ formateTime(img.CreatedOn) }}
                    </p>
                    <a
                      class="yellow-btn fs-12"
                      style="display: block; margin-top: 11px; line-height: 26px"
                      @click="watchVideo(img.VimeoURL)"
                      >Watch Video</a
                    >
                    <img class="class-image" :src="require(`../../assets/cardellipse.svg`)" />
                  </div>
                </div>
                <div
                  v-if="img.CardType === 'LiveClass' && img.CardType !== 'AVReport'"
                  class="slideshow1-container flex card-shadow card-radius class-card"
                >
                  <div class="fade relative" style="padding: 16px; margin-top: 15px">
                    <h2 class="fs-18 fw-700 dark-text" style="color: #ffffff !important">
                      {{ img.Title }}
                    </h2>
                    <p class="fs-10 fw-400" style="color: #ffffff !important; margin-top: 15px">
                      <br /><span class="fs-15 fw-400">{{ img.SubjectName }}</span>
                    </p>
                    <p class="fs-11 fw-400" style="color: #ffffff !important; margin-top: 20px">
                      Duration {{ img.Duration }} min
                    </p>
                    <p class="fs-10 fw-600" style="color: #ffffff !important">
                      Faculty: <span class="fw-400"></span> {{ img.FacultyName }}
                    </p>
                    <button @click="joinClass(img.Url)" class="yellow-btn" style="margin-top: 11px">
                      Join Class
                    </button>
                    <img class="class-image" :src="require(`../../assets/cardellipse.svg`)" />
                  </div>
                </div>
                <!-- The dots/circles -->
              </div>
              <!-- <div> -->
              <!-- <template slot="prev"><span class="prev">prev</span></template>
                          <template slot="next"><span class="next">next</span></template> -->
              <!-- </div> -->
              <template #viewport>
                <div class="flicking-pagination"></div>
              </template>
            </Flicking>
            <!-- <div style="text-align:left;top: -10px">
                          <span class="dot" @click="prevSlide()"></span>
                          <span class="dot" @click="nextSlide()"></span> -->
            <!-- <span class="dot" @click="thirdSlide()"></span> -->
            <!-- </div> -->
            <br />
          </div>
          <div
            v-if="this.avgreport.length == 0 && this.liveClass.length == 0"
            class="flex coverage-report"
          >
            <div class="take-test white-bgc card-shadow card-radius card-border">
              <h2 class="purple-text fs-15 fw-700">Subject Coverage Report</h2>
              <!-- <button @click="psycotest()" class="yellow-btn">Take test</button> -->
              <p style="white-space: break-spaces">
                Your subject coverage report will be generated once you have practiced sufficient
                questions. Check again after some time.
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- Recommendation Section -->
      <div class="recommend-section">
        <div class="recommend-sec">
          <h1 class="fs-18 fw-700 dark-text top-header">Recommendations</h1>
          <div
            class="recommend-content white-bgc card-radius card-border card-shadow"
            style="padding: 16px 15px"
          >
            <!-- Practice -->
            <div class="recommendation practice" v-if="this.getNewStudentDashboardpra.length !== 0">
              <div class="recommend-top flex items-center">
                <h2 class="fs-15 purple-text fw-700" style="margin-bottom: 5px; margin-right: 45px">
                  Practice
                </h2>
                <div v-dragscroll class="flex items-center scroll-horizontal">
                  <button class="gray-btn" :class="{ active: isActive }" @click="allPractice()">
                    All
                  </button>
                  <div
                    v-for="(subject, index) in getNewStudentDashboardsub"
                    :key="subject.subjectId"
                    :value="subject.subjectId"
                    @click="getsubdata(subject, index)"
                  >
                    <button class="gray-btn" :class="{ active: activeIndex === index }">
                      {{
                        subject.subjectName.length > 12
                          ? subject.subjectName.slice(0, 12) + ".."
                          : subject.subjectName
                      }}
                    </button>
                  </div>
                </div>
              </div>
              <div
                v-dragscroll
                v-if="this.subjectList.length !== 0"
                class="recommendation-content flex scroll-horizontal"
              >
                <div
                  class="main-recommendation-box"
                  v-for="(Chapter, index) in subjectList"
                  :key="index"
                  :value="Chapter.chapterId"
                >
                  <ClassCard
                    :label1="Chapter.chapterName"
                    :subjectIcon="Chapter.subjectIcon"
                    :buttonName="'Start'"
                    :backgroundColor="'#FFECDB'"
                    :label3="Chapter.subjectName"
                    :data="Chapter"
                    :progressBar="true"
                    @cardData="startPractice(Chapter)"
                  ></ClassCard>
                </div>
                <div class="main-recommendation-box">
                  <div
                    class="recommend-box recommendation-box relative lightPink-bgc card-radius-2"
                    style="padding: 12px; overflow: hidden; background: #e3e3e3 !important"
                  >
                    <div class="flex flex-between flex-column">
                      <img
                        style="height: 25px; margin-top: 5px; margin-bottom: 5px"
                        :src="require(`../../assets/newsession.svg`)"
                        @click="startNewPractice(Chapter)"
                      />
                      <p class="fs-12 fw-600 center-align" style="opacity: 0.6">
                        Start Your Own Practice
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="this.subjectList.length === 0">
                <div class="main-recommendation-box">
                  <div
                    class="recommend-box recommendation-box relative lightPink-bgc card-radius-2"
                    style="padding: 12px; overflow: hidden; background: #e3e3e3 !important"
                  >
                    <div class="flex flex-between flex-column">
                      <img
                        style="height: 25px; margin-top: 5px; margin-bottom: 5px"
                        :src="require(`../../assets/newsession.svg`)"
                        @click="startNewPractice(Chapter)"
                      />
                      <p class="fs-12 fw-600" style="opacity: 0.6; margin-left: 5px">
                        Start Your Own Practice
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Assignment -->
            <div
              class="recommendation"
              style="padding-top: 26px"
              v-if="this.getAssignment.length > 0"
            >
              <div class="recommend-top flex items-center">
                <h2 class="fs-15 purple-text fw-700" style="margin-bottom: 5px; margin-right: 10px">
                  Assignments
                </h2>
                <div v-dragscroll class="flex items-center scroll-horizontal">
                  <button class="gray-btn" :class="{ active: isActive1 }" @click="allassignments()">
                    All
                  </button>
                  <div
                    v-for="(subject, index) in getNewStudentDashboardsub"
                    :key="subject.subjectId"
                    :value="subject.subjectId"
                    @click="getAssignmentData(subject, index)"
                  >
                    <button class="gray-btn" :class="{ active: activeIndex1 === index }">
                      {{
                        subject.subjectName.length > 12
                          ? subject.subjectName.slice(0, 12) + ".."
                          : subject.subjectName
                      }}
                    </button>
                  </div>
                </div>
              </div>
              <div v-dragscroll class="recommendation-content flex scroll-horizontal">
                <div
                  class="main-recommendation-box"
                  v-for="Chapter in this.assignmentSubjectList"
                  :key="Chapter.chapterId"
                >
                  <ClassCard
                    :label1="Chapter.assignmentName"
                    :backgroundColor="'#EBFFE7'"
                    :buttonName="'Start'"
                    :label3="Chapter.subjectName"
                    :reason="false"
                    :data="Chapter"
                    :subjectIcon="Chapter.subjectIcon"
                    :timer="true"
                    @cardData="startAssignment($event)"
                  />
                </div>
              </div>
            </div>
            <!-- Tests -->
            <div
              v-if="
                this.getNewStudentDashboardst.length !== 0 ||
                this.getInsituteTest.length !== 0 ||
                this.selfList.length !== 0
              "
              class="recommendation tests"
              style="padding-top: 26px"
            >
              <div class="recommend-top flex items-center">
                <h2 class="fs-15 purple-text fw-700" style="margin-bottom: 5px; margin-right: 65px">
                  Tests
                </h2>
                <div v-dragscroll class="flex items-center scroll-horizontal">
                  <button class="gray-btn" :class="{ active: isActive2 }" @click="allSelfTest()">
                    All
                  </button>
                  <div
                    v-for="(subject, index) in getNewStudentDashboardsub"
                    :key="subject.subjectId"
                    :value="subject.subjectId"
                    @click="getselfdata(subject, index)"
                  >
                    <button class="gray-btn" :class="{ active: activeIndex2 === index }">
                      {{
                        subject.subjectName.length > 12
                          ? subject.subjectName.slice(0, 12) + ".."
                          : subject.subjectName
                      }}
                    </button>
                  </div>
                </div>
              </div>
              <div v-dragscroll class="recommendation-content flex scroll-horizontal">
                <div
                  class="main-recommendation-box"
                  v-for="Chapter in getInsituteTest"
                  :key="Chapter.chapterId"
                >
                  <ClassCard
                    :label1="Chapter.instituteTestName"
                    :backgroundColor="`linear-gradient(98.34deg,#e9ccff 11.31%,#f4e6ff 88.13%)`"
                    :buttonName="'Start'"
                    :label3="Chapter.subjectName"
                    :reason="true"
                    :data="Chapter"
                    :subjectIcon="Chapter.subjectIcon"
                    :timer="true"
                    @cardData="startInstituteTest($event.instituteTestId)"
                  />
                </div>
                <!-- Self test -->
                <div
                  class="main-recommendation-box"
                  v-for="Chapter in selfList"
                  :key="Chapter.chapterId"
                >
                  <ClassCard
                    :label1="Chapter.chapterName"
                    :label3="Chapter.subjectName"
                    :buttonName="'Start'"
                    :backgroundColor="'#DCEAFF'"
                    :subjectIcon="Chapter.subjectIcon"
                    :reason="true"
                    :data="Chapter"
                    :progressBar="true"
                    @cardData="startSelfTestFull($event)"
                  />
                </div>
                <div class="main-recommendation-box">
                  <div
                    class="recommend-box recommendation-box relative lightPink-bgc card-radius-2"
                    style="padding: 12px; overflow: hidden; background: #e3e3e3 !important"
                  >
                    <div class="flex flex-between flex-column">
                      <img
                        class="pointer"
                        style="height: 25px; margin-top: 5px; margin-bottom: 5px"
                        :src="require(`../../assets/newsession.svg`)"
                        @click="startNewSelfTest()"
                      />
                      <p class="fs-12 fw-600 center-align" style="opacity: 0.6">
                        Create Your Own Self Test
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Videos -->
            <div
              v-if="this.getNewStudentDashboardLearn.length !== 0 && this"
              class="recommendation tests video"
              style="padding-top: 26px"
            >
              <div class="recommend-top flex items-center">
                <h2 class="fs-15 purple-text fw-700" style="margin-bottom: 5px; margin-right: 54px">
                  Videos
                </h2>
                <div v-dragscroll class="flex items-center scroll-horizontal">
                  <button class="gray-btn" :class="{ active: isActive3 }" @click="allvideo()">
                    All
                  </button>

                  <div
                    v-for="(subject, index) in getNewStudentDashboardsub"
                    :key="subject.subjectId"
                    :value="subject.subjectId"
                    @click="videoselect(subject, index)"
                  >
                    <button class="gray-btn" :class="{ active: activeIndex3 === index }">
                      {{
                        subject.subjectName.length > 12
                          ? subject.subjectName.slice(0, 12) + ".."
                          : subject.subjectName
                      }}
                    </button>
                  </div>
                </div>
              </div>
              <div v-dragscroll class="recommendation-content flex scroll-horizontal">
                <div
                  class="main-recommendation-box"
                  v-for="video in this.filteredLearnData"
                  :key="video.videoId"
                >
                  <ClassCard
                    :label1="video.videoTitle"
                    :subjectIcon="video.subjectIcon"
                    :buttonName="'Watch'"
                    :backgroundColor="'#FFFBD7'"
                    :label3="video.subjectName"
                    :data="video"
                    @cardData="
                      recommendVideoUrl = video.videoURL;
                      showRecommendVideo = true;
                    "
                    :reason="true"
                  ></ClassCard>
                </div>
                <div class="main-recommendation-box">
                  <div
                    class="recommend-box recommendation-box relative lightPink-bgc card-radius-2"
                    style="padding: 12px; overflow: hidden; background: #e3e3e3 !important"
                  >
                    <div class="flex flex-between flex-column">
                      <img
                        style="height: 25px; margin-top: 5px; margin-bottom: 5px"
                        :src="require(`../../assets/newsession.svg`)"
                        @click="selectNewVideo()"
                      />
                      <p class="fs-12 fw-600 center-align" style="opacity: 0.6">Watch all Videos</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Leaderboard & Rewards Section -->
      <div class="leaderboard_reward-section">
        <!-- leaderboard -->
        <div class="leaderboard">
          <div class="flex flex-between items-baseline">
            <h1 class="fs-18 fw-700 dark-text top-header">Leaderboard</h1>
            <a
              class="fs-11 fw-600 skyblue-text flex items-center flex-between"
              @click="showleaderboardDetail = !showleaderboardDetail"
              style="margin-right: 3px"
              >Details<i class="material-icons fs-15" style="margin-left: 3px">{{
                showleaderboardDetail ? "expand_less" : "expand_more"
              }}</i></a
            >
          </div>
          <div class="white-bgc card-radius card-shadow card-border leader-card">
            <!-- <div class="flex flex-between leader-header" style="padding-bottom: 3px;">
                          <h3 class="fs-10 black-text fw-400 relative" style="opacity: 0.4; left: 30px;">Name</h3>
                          <h3 class="fs-10 black-text fw-400" style="opacity: 0.4; margin-right: 7px;">Rank</h3>
                      </div> -->
            <div class="leader-body scroll-verticle" v-if="showleaderboardDetail === false">
              <div
                class="flex leader-top items-center flex-between"
                v-for="(Leaders, index) in getLeaderBoardData"
                :key="index"
              >
                <div class="flex" v-if="Object.keys(Leaders).length > 0">
                  <ProfileAvatar
                    style="border: none"
                    :username="Leaders.name.charAt(0)"
                    class="leader-avatar"
                    :bgColor="leaderBg[index] + '!important'"
                  ></ProfileAvatar>
                  <h3
                    class="fs-12 black-text fw-600"
                    style="white-space: nowrap; line-height: 20px"
                  >
                    {{
                      Leaders.name.length > 16 ? Leaders.name.slice(0, 16) + "..." : Leaders.name
                    }}
                  </h3>
                </div>
                <div v-else style="position: relative; left: 8px; top: 2px">
                  <img :src="require(`../../assets/saperator.svg`)" alt="" />
                </div>
                <h3 class="fs-12 skyblue-text fw-600 relative" style="left: -15px">
                  {{ Leaders.rank }}
                </h3>
              </div>
            </div>
            <div
              class="leader-body scroll-verticle leader-details"
              v-if="showleaderboardDetail === true"
            >
              <div class="leader-top" v-for="(Leaders, index) in getLeaderBoardData" :key="index">
                <div class="flex items-center flex-between" v-if="Object.keys(Leaders).length > 0">
                  <div class="flex">
                    <ProfileAvatar
                      style="border: none"
                      :username="Leaders.name.charAt(0)"
                      class="leader-avatar"
                      :bgColor="leaderBg[index] + '!important'"
                    ></ProfileAvatar>
                    <h3
                      class="fs-12 black-text fw-600"
                      style="white-space: nowrap; line-height: 20px"
                    >
                      {{
                        Leaders.name.length > 16 ? Leaders.name.slice(0, 16) + "..." : Leaders.name
                      }}
                    </h3>
                  </div>
                  <h3
                    class="fs-12 skyblue-text fw-600 relative"
                    style="left: -15px; line-height: 20px"
                  >
                    {{ Leaders.rank }}
                  </h3>
                </div>
                <div class="flex" style="margin-left: 28px" v-if="Object.keys(Leaders).length > 0">
                  <p class="flex fs-10 dark-text fw-600 leader-para">
                    <img :src="require(`../../assets/Question.svg`)" style="margin-right: 5px" />{{
                      Leaders.Attempted
                    }}
                  </p>
                  <!-- <p class="flex fs-10 black-text fw-400 leader-para"><span class="material-icons material-symbols-outlined" style="font-size: 20px; color: rgb(46 43 43);margin-right: 5px;">help</span> {{Leader.SLScore}}</p> -->
                  <p class="flex fs-10 black-text fw-600 leader-para">
                    <img
                      :src="require(`../../assets/target (1) 1.svg`)"
                      style="margin-right: 5px"
                    />{{ Leaders.Accuracy }}
                  </p>
                </div>
                <div v-else style="position: relative; left: 8px; top: 2px">
                  <img :src="require(`../../assets/saperator.svg`)" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isPromotionEnable === true">
          <h1 class="fs-18 fw-700 dark-text top-header" style="margin-top: 10px">Test</h1>
          <div class="take-test-card">
            <div class="fade relative" style="padding: 15px; width: 100%">
              <div class="flex flex-between items-center" style="margin-bottom: 15px">
                <h2 class="fw-700" style="color: #ffffff !important; font-size: 20px">
                  LF MoY Test
                </h2>
                <img :src="require(`../../assets/Timer.svg`)" alt="" />
              </div>
              <a
                class="test-button fs-12"
                style="display: block; margin-top: 11px; line-height: 26px"
                @click="takeTest()"
                >Start</a
              >
              <img
                class="test-image"
                style="height: 75px"
                :src="require(`../../assets/cardellipse.svg`)"
              />
            </div>
          </div>
        </div>
        <!-- reward -->
        <div class="reward">
          <!-- <div class="leaderboard"> -->
          <h1 class="fs-18 fw-700 dark-text top-header">Rewards</h1>
          <div class="white-bgc card-radius card-shadow card-border">
            <div class="reward-header">
              <h4 class="fs-9 fw-600 white-text" style="opacity: 0.7">Available Balance</h4>
              <div class="flex">
                <img
                  :src="require(`../../assets/Group91804.svg`)"
                  alt="reward"
                  style="margin-right: 6.25px"
                />
                <p
                  class="fs-22 white-text fw-700"
                  v-for="coins in this.totalFilteredCoins"
                  :key="coins.userId"
                >
                  {{ coins.CoinTransacted }}
                </p>
              </div>
            </div>
            <div class="reward-body">
              <ul class="reward-list">
                <li
                  class="fs-12 fw-600 black-text flex flex-between items-center"
                  v-for="coins in this.filteredCoins"
                  :key="coins.userId"
                >
                  {{ coins.ActivityName }}
                  <div class="purple-text fw-700">
                    {{ coins.CoinTransacted }}&nbsp;<img
                      :src="require(`../../assets/Group-list.svg`)"
                      alt=""
                    />
                  </div>
                </li>
              </ul>
              <!-- <a href="#0" class="skyblue-text fs-11 fw--600" style="cursor: pointer; padding-top: 12px;display: block; margin-bottom: 10px;">Redemption history <img :src="require(`../../assets/Vector 1.svg`)" style="margin-left: 7px;"></a> -->
              <div>
                <!-- <button class="yellow-btn" style="width: 100%; height: 24px;">Redeem Now</button> -->
                <button
                  class="yellow-btn skyblue-text"
                  style="background: #f3f5fc !important; width: 100%; height: 24px; margin-top: 5px"
                  @click="showHowToEarn = true"
                >
                  How to Earn
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- </div> -->
      </div>
    </div>
    <Modal :show="showSelectClassModal" :showHeader="false">
      <template v-slot:body>
        <h1 class="fs-18 fw-400 dark-text top-header center-align">
          Welcome <strong>{{ this.userDetails.UserName }} !!</strong>
        </h1>
        <div style="display: flex; justify-content: center">
          <img class="center-align" :src="require(`../../assets/school.png`)" />
        </div>
        <p class="center-align modal-select">Select Your Class</p>
        <div class="flex flex-around center-align" style="width: 450px">
          <button
            v-for="cls in getclass"
            :key="`cls${cls.ClassId}`"
            class="modal-button"
            :class="{ selected: userClassId == cls.ClassId }"
            @click="onClassChange(cls.ClassId, cls.DisplayName)"
          >
            {{ cls.ClassName }}
          </button>
          <!-- <div class="row" v-if="!selectedTopics.length"><div class="col s12 center-align materialize-red-text">Please select a topic</div></div> -->
        </div>
      </template>
    </Modal>
    <Modal2
      :show="showHowToEarn"
      :showHeader="false"
      :showCloseBtn="false"
      @close="showHowToEarn = false"
    >
      <template v-slot:body>
        <div class="earn-modal">
          <div class="flex flex-center earn-head">
            <h1 class="fw-700 skyblue-text center" style="font-size: 20px">How to Earn</h1>
            <p
              @click="showHowToEarn = false"
              style="position: absolute; right: 17px; cursor: pointer"
            >
              <span class="material-icons earn-close">close</span>
            </p>
          </div>
          <div class="hw-to-earn-content">
            <div class="earn-card">
              <h1>Practice</h1>
              <div style="padding: 15px 19px 12px">
                <div>
                  <h2 class="fs-15 fw-600 dark-text">Questions attempted *</h2>
                  <div class="flex flex-between items-center">
                    <p class="fs-14 fw-400 black-text earn-para">Every 20 questions attempted</p>
                    <p class="fs-15 fw-600 purple-text flex items-center">
                      <span style="margin-right: 6px">2</span>
                      <img :src="require(`../../assets/coin.svg`)" alt="" />
                    </p>
                  </div>
                </div>
                <div>
                  <h2 class="fs-15 fw-600 dark-text" style="margin-top: 14px">
                    Practice accuracy **
                  </h2>
                  <div class="flex flex-between">
                    <p class="fs-14 fw-400 black-text earn-para">Session accuracy > 95%</p>
                    <p class="fs-15 fw-600 purple-text flex items-center">
                      <span style="margin-right: 6px">30</span>
                      <img :src="require(`../../assets/coin.svg`)" alt="" />
                    </p>
                  </div>
                  <div class="flex flex-between">
                    <p class="fs-14 fw-400 black-text earn-para">Session accuracy > 90%</p>
                    <p class="fs-15 fw-600 purple-text flex items-center">
                      <span style="margin-right: 6px">20</span>
                      <img :src="require(`../../assets/coin.svg`)" alt="" />
                    </p>
                  </div>
                  <div class="flex flex-between">
                    <p class="fs-14 fw-400 black-text earn-para">Session accuracy > 80%</p>
                    <p class="fs-15 fw-600 purple-text flex items-center">
                      <span style="margin-right: 6px">10</span>
                      <img :src="require(`../../assets/coin.svg`)" alt="" />
                    </p>
                  </div>
                </div>
              </div>
              <p class="dark-text earn-bottom truncate">
                *Min 50% accuracy | **Min 30 questions attempted
              </p>
            </div>
            <div class="earn-card">
              <h1>Self Test</h1>
              <div style="padding: 15px 19px 12px">
                <div>
                  <h2 class="fs-15 fw-600 dark-text">Test duration *</h2>
                  <div class="flex flex-between items-center">
                    <p class="fs-14 fw-400 black-text earn-para">Every 20 mins of time taken</p>
                    <p class="fs-15 fw-600 purple-text flex items-center">
                      <span style="margin-right: 6px">5</span>
                      <img :src="require(`../../assets/coin.svg`)" alt="" />
                    </p>
                  </div>
                </div>
                <div>
                  <h2 class="fs-15 fw-600 dark-text" style="margin-top: 14px">Test accuracy **</h2>
                  <div class="flex flex-between">
                    <p class="fs-14 fw-400 black-text earn-para">Test accuracy > 95%</p>
                    <p class="fs-15 fw-600 purple-text flex items-center">
                      <span style="margin-right: 6px">60</span>
                      <img :src="require(`../../assets/coin.svg`)" alt="" />
                    </p>
                  </div>
                  <div class="flex flex-between">
                    <p class="fs-14 fw-400 black-text earn-para">Test accuracy > 90%</p>
                    <p class="fs-15 fw-600 purple-text flex items-center">
                      <span style="margin-right: 6px">40</span>
                      <img :src="require(`../../assets/coin.svg`)" alt="" />
                    </p>
                  </div>
                  <div class="flex flex-between">
                    <p class="fs-14 fw-400 black-text earn-para">Test accuracy > 80%</p>
                    <p class="fs-15 fw-600 purple-text flex items-center">
                      <span style="margin-right: 6px">20</span>
                      <img :src="require(`../../assets/coin.svg`)" alt="" />
                    </p>
                  </div>
                </div>
              </div>
              <p class="dark-text earn-bottom truncate">
                *Min 50% accuracy | ** Min 20 questions in test
              </p>
            </div>
            <div class="earn-card" style="height: 340px">
              <div class="flex flex-between items-center" style="background: #71e0d3">
                <h1>Assignments</h1>
                <p class="yellow-btn coming-soon-btn">
                  <img :src="require(`../../assets/coming-soon.svg`)" alt="" /> Coming soon
                </p>
              </div>
              <div style="padding: 15px 19px 24px">
                <div>
                  <h2 class="fs-15 fw-600 dark-text">First to finish *</h2>
                  <div class="flex flex-between items-center">
                    <p class="fs-14 fw-400 black-text earn-para">Rank 1</p>
                    <p class="fs-15 fw-600 purple-text flex items-center">
                      <span style="margin-right: 6px">15</span>
                      <img :src="require(`../../assets/coin.svg`)" alt="" />
                    </p>
                  </div>
                  <div class="flex flex-between items-center">
                    <p class="fs-14 fw-400 black-text earn-para">Rank 2 - 3</p>
                    <p class="fs-15 fw-600 purple-text flex items-center">
                      <span style="margin-right: 6px">10</span>
                      <img :src="require(`../../assets/coin.svg`)" alt="" />
                    </p>
                  </div>
                  <div class="flex flex-between items-center">
                    <p class="fs-14 fw-400 black-text earn-para">Rank 4 - 5</p>
                    <p class="fs-15 fw-600 purple-text flex items-center">
                      <span style="margin-right: 6px">5</span>
                      <img :src="require(`../../assets/coin.svg`)" alt="" />
                    </p>
                  </div>
                </div>
                <div>
                  <h2 class="fs-15 fw-600 dark-text" style="margin-top: 14px">
                    Assignment accuracy **
                  </h2>
                  <div class="flex flex-between">
                    <p class="fs-14 fw-400 black-text earn-para">Rank 1</p>
                    <p class="fs-15 fw-600 purple-text flex items-center">
                      <span style="margin-right: 6px">30</span>
                      <img :src="require(`../../assets/coin.svg`)" alt="" />
                    </p>
                  </div>
                  <div class="flex flex-between">
                    <p class="fs-14 fw-400 black-text earn-para">Rank 2</p>
                    <p class="fs-15 fw-600 purple-text flex items-center">
                      <span style="margin-right: 6px">20</span>
                      <img :src="require(`../../assets/coin.svg`)" alt="" />
                    </p>
                  </div>
                  <div class="flex flex-between">
                    <p class="fs-14 fw-400 black-text earn-para">Rank 3</p>
                    <p class="fs-15 fw-600 purple-text flex items-center">
                      <span style="margin-right: 6px">10</span>
                      <img :src="require(`../../assets/coin.svg`)" alt="" />
                    </p>
                  </div>
                </div>
              </div>
              <p class="dark-text earn-bottom truncate">
                *Min 50% accuracy, min 20 Qs in assignment | **Min 20 Qs in assignment
              </p>
            </div>
            <div class="earn-card" style="height: 340px">
              <h1>Institute Test</h1>
              <div style="padding: 15px 19px 12px">
                <div>
                  <h2 class="fs-15 fw-600 dark-text">Test Rank *</h2>
                  <div class="flex flex-between items-center">
                    <p class="fs-14 fw-400 black-text earn-para">Rank 1</p>
                    <p class="fs-15 fw-600 purple-text flex items-center">
                      <span style="margin-right: 6px">50</span>
                      <img :src="require(`../../assets/coin.svg`)" alt="" />
                    </p>
                  </div>
                  <div class="flex flex-between items-center">
                    <p class="fs-14 fw-400 black-text earn-para">Rank 2</p>
                    <p class="fs-15 fw-600 purple-text flex items-center">
                      <span style="margin-right: 6px">30</span>
                      <img :src="require(`../../assets/coin.svg`)" alt="" />
                    </p>
                  </div>
                  <div class="flex flex-between items-center">
                    <p class="fs-14 fw-400 black-text earn-para">Rank 3 - 5</p>
                    <p class="fs-15 fw-600 purple-text flex items-center">
                      <span style="margin-right: 6px">20</span>
                      <img :src="require(`../../assets/coin.svg`)" alt="" />
                    </p>
                  </div>
                  <div class="flex flex-between items-center">
                    <p class="fs-14 fw-400 black-text earn-para">Rank 6 - 10</p>
                    <p class="fs-15 fw-600 purple-text flex items-center">
                      <span style="margin-right: 6px">10</span>
                      <img :src="require(`../../assets/coin.svg`)" alt="" />
                    </p>
                  </div>
                </div>
              </div>
              <p class="dark-text earn-bottom truncate" style="margin-top: 102px">
                *Coins given to top 50% students only
              </p>
            </div>
            <div class="earn-card" style="height: 241px">
              <div class="flex flex-between items-center" style="background: #f5c1bd">
                <h1>Referral</h1>
                <p class="yellow-btn coming-soon-btn">
                  <img :src="require(`../../assets/coming-soon.svg`)" alt="" /> Coming soon
                </p>
              </div>
              <div style="padding: 6px 19px 12px">
                <div>
                  <!-- <h2 class="fs-15 fw-600 dark-text">Test Rank *</h2> -->
                  <div class="flex flex-between items-center">
                    <p class="fs-14 fw-400 black-text earn-para">Single referral</p>
                    <!-- <p class="fs-15 fw-600 purple-text flex items-center"> <span style="margin-right: 6px;">50</span> <img :src="require(`../../assets/coin.svg`)" alt=""></p> -->
                  </div>
                  <div class="flex flex-between items-center">
                    <p class="fs-14 fw-400 black-text earn-para">10 referral bonus</p>
                    <!-- <p class="fs-15 fw-600 purple-text flex items-center"> <span style="margin-right: 6px;">30</span> <img :src="require(`../../assets/coin.svg`)" alt=""></p> -->
                  </div>
                  <!-- <div class="flex flex-between items-center">
                                      <p class="fs-14 fw-400 black-text earn-para">Rank 3 - 5</p>
                                      <p class="fs-15 fw-600 purple-text flex items-center"> <span style="margin-right: 6px;">20</span> <img :src="require(`../../assets/coin.svg`)" alt=""></p>
                                  </div>
                                  <div class="flex flex-between items-center">
                                      <p class="fs-14 fw-400 black-text earn-para">Rank 6 - 10</p>
                                      <p class="fs-15 fw-600 purple-text flex items-center"> <span style="margin-right: 6px;">10</span> <img :src="require(`../../assets/coin.svg`)" alt=""></p>
                                  </div> -->
                </div>
              </div>
            </div>
            <div class="earn-card" style="height: 241px">
              <div class="flex flex-between items-center" style="background: #f5bddc">
                <h1>Login</h1>
                <p class="yellow-btn coming-soon-btn">
                  <img :src="require(`../../assets/coming-soon.svg`)" alt="" /> Coming soon
                </p>
              </div>
              <div style="padding: 15px 19px 24px">
                <div>
                  <h2 class="fs-15 fw-600 dark-text">Consecutive day logins</h2>
                  <div class="flex flex-between items-center">
                    <p class="fs-14 fw-400 black-text earn-para">3 consecutive days</p>
                    <p class="fs-15 fw-600 purple-text flex items-center">
                      <span style="margin-right: 6px">10</span>
                      <img :src="require(`../../assets/coin.svg`)" alt="" />
                    </p>
                  </div>
                  <div class="flex flex-between items-center">
                    <p class="fs-14 fw-400 black-text earn-para">6 consecutive days</p>
                    <p class="fs-15 fw-600 purple-text flex items-center">
                      <span style="margin-right: 6px">20</span>
                      <img :src="require(`../../assets/coin.svg`)" alt="" />
                    </p>
                  </div>
                  <div class="flex flex-between items-center">
                    <p class="fs-14 fw-400 black-text earn-para">3n consecutive days (n > 3)</p>
                    <p class="fs-15 fw-600 purple-text flex items-center">
                      <span style="margin-right: 6px">30</span>
                      <img :src="require(`../../assets/coin.svg`)" alt="" />
                    </p>
                  </div>
                </div>
                <div>
                  <h2 class="fs-15 fw-600 dark-text" style="margin-top: 14px">
                    Total time spent in a day
                  </h2>
                  <div class="flex flex-between">
                    <p class="fs-14 fw-400 black-text earn-para">Every 2 hours completed</p>
                    <p class="fs-15 fw-600 purple-text flex items-center">
                      <span style="margin-right: 6px">10</span>
                      <img :src="require(`../../assets/coin.svg`)" alt="" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Modal2>
    <Modal2
      :show="showAvVideo"
      :showHeader="false"
      :showCloseBtn="false"
      @close="showAvVideo = false"
    >
      <template v-slot:body>
        <div class="flex flex-center earn-head">
          <p
            @click="
              showAvVideo = false;
              avVideoUrl = '';
            "
            style="position: absolute; right: 17px; cursor: pointer; top: 8px"
          >
            <span class="material-icons earn-close">close</span>
          </p>
        </div>
        <iframe
          :src="avVideoUrl"
          frameborder="0"
          allow=" fullscreen; picture-in-picture"
          allowfullscreen
          class="videoModal"
        ></iframe>
      </template>
    </Modal2>
    <Modal2
      :show="showRecommendVideo"
      :showHeader="false"
      :showCloseBtn="false"
      @close="showRecommendVideo = false"
    >
      <template v-slot:body>
        <div class="flex flex-center earn-head">
          <p
            @click="
              showRecommendVideo = false;
              recommendVideoUrl = '';
            "
            style="position: absolute; right: 17px; cursor: pointer; top: 8px"
          >
            <span class="material-icons earn-close">close</span>
          </p>
        </div>
        <iframe
          :src="recommendVideoUrl"
          frameborder="0"
          allow=" fullscreen; picture-in-picture"
          allowfullscreen
          class="videoModal"
        ></iframe>
      </template>
    </Modal2>
  </div>
</template>

<script>
// import ProgressBar from "vuejs-progress-bar";
import moment from "moment";
import ProfileAvatar from "vue-profile-avatar";
import Flicking from "@egjs/vue3-flicking";
import { Pagination, AutoPlay } from "@egjs/flicking-plugins";
import API from "../../Api";
import MobileAPI from "../../Mobileapi";
import Multiple from "../../assets/Multiple.png";
import Modal from "../../components/Modal.vue";
import Modal2 from "../../components/Model2.vue";
import "@egjs/flicking-plugins/dist/pagination.css";
import ClassCard from "../../components/ClassCard.vue";

export default {
  data() {
    return {
      plugins: [
        new Pagination({
          type: "bullet",
        }),
        new AutoPlay({
          duration: 10000,
          direction: "NEXT",
          stopOnHover: false,
        }),
      ],
      randomImage: Multiple,
      activeIndex: null,
      activeIndex1: null,
      activeIndex2: null,
      activeIndex3: null,
      avgreport: [],
      getNewStudentDashboardsub: [],
      getNewStudentDashboardpra: [],
      getNewStudentDashboardLearn: [],
      subjectList: [],
      assignmentSubjectList: [],
      getInsituteTest: [],
      selfList: [],
      getAssignment: [],
      getNewStudentDashboardst: [],
      getLeaderBoardData: [],
      filteredCoins: [],
      totalFilteredCoins: [],
      allchapters: [],
      vimeovideo: [],
      videos: [],
      isActive: false,
      isActive1: false,
      isActive2: false,
      isActive3: false,
      isSelected: null,
      SelectedSubjectid: "",
      showSelectClassModal: false,
      isclassMapped: false,
      getclass: [],
      userCourseId: "",
      userClassId: "",
      userDetails: "",
      selectedClass: null,
      liveClass: [],
      showReport: false,
      avreportlength: null,
      listofVimeoVideos: [],
      showDashboard: false,
      showImg: 0,
      showClass: true,
      height: 0,
      showParentCard: false,
      leaderBg: [],
      showleaderboardDetail: true,
      options: {
        text: {
          color: "#FFFFFF",
          shadowEnable: true,
          shadowColor: "#000000",
          fontSize: 14,
          fontFamily: "Helvetica",
          dynamicPosition: false,
          hideText: false,
        },
        progress: {
          color: "#2dbd2d",
          backgroundColor: "#333333",
          inverted: false,
        },
        layout: {
          height: 35,
          width: 140,
          verticalTextAlign: 61,
          horizontalTextAlign: 43,
          zeroOffset: 0,
          strokeWidth: 30,
          progressPadding: 0,
          type: "line",
        },
        parentRequest: {
          ParentStudentLinkId: "",
          Name: "",
          EmailId: "",
          Mobile: "",
          CreatedOn: "",
          Date: "",
        },
      },
      showHowToEarn: false,
      showAvVideo: false,
      avVideoUrl: "",
      showRecommendVideo: false,
      recommendVideoUrl: "",
      filteredLearnData: [],
      dashboardUserDetails: null,
      dashboardClassId: null,
      dashboardClassName: "",
      dashboardCourseId: null,
      dashboardSubjectId: null,
      isPromotionEnable: false,
      testUrl: "",
      count: 0,
    };
  },
  activated() {
    const user = this.$store.getters.user;
    this.userDetails = user;
    this.dashboardUserDetails = this.userDetails;
    this.leaderBg = [
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#F0AE76",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#F0AE76",
    ];
    MobileAPI.getStudentDetails(user.UserId, (response) => {
      this.userCourseId = response.data.courseId;
      this.dashboardCourseId = this.userCourseId;
      MobileAPI.getClass(
        {
          UserId: this.userDetails.UserId,
          isPractice: 0,
        },
        (response1) => {
          if (response1.data !== null && response1.data.length > 0) {
            this.getclass = response1.data;
            if (this.getclass.length === 2) {
              this.showSelectClassModal = true;
            } else {
              this.userClassId = this.getclass[0].ClassId;
              this.dashboardClassId = this.userClassId;
              this.dashboardClassName = this.getclass[0].DisplayName;
              this.setInitialPageLoadDetails(user, this.userCourseId, this.userClassId);
            }
          }
        },
      );
    });
    const data = {
      userId: this.userDetails.UserId,
      serverToken: localStorage.getItem("SLToken"),
    };
    this.getParentRequests();
    this.getPromotion();
    MobileAPI.getOnlineClassStudent(data, (response2) => {
      if (response2.data.scheduleClass !== null) {
        this.liveClass = response2.data.scheduleClass;
        // this.liveClass = [{
        //   BatchName: "Demo NEET",
        //   ClassType: "Zoom",
        //   CourseId: 4,
        //   CourseName: "11/12 CBSE",
        //   Description: "",
        //   Duration: 35,
        //   FacultyName: "11/12 Faculty",
        //   Id: 2428,
        //   InterestedStudentsCount: 0,
        //   LiveClassThumbnailUrl: "",
        //   MeetingId: "162-1035",
        //   OnlineClassId: 1035,
        //   PasswordZoom: "",
        //   RecordingPassword: "",
        //   RecordingUrl: "",
        //   Schedule: "2022-03-29T17:30:00.000Z",
        //   ServerId: 0,
        //   Status: "Completed",
        //   SubjectId: 3,
        //   SubjectName: "Chemistry",
        //   Title: "NET",
        //   Url: "https://zoom.us/j/93012822485",
        // }];
      }
    });
  },
  components: {
    ProfileAvatar,
    Flicking,
    Modal,
    Modal2,
    ClassCard,
  },
  //   beforeCreate() {
  //     document.querySelector("body").setAttribute("style", "background:#F6F8FB");
  //   },
  //   beforeUnmount() {
  //     document.querySelector("body").setAttribute("style", "background:#f6f1ec");
  //   },
  methods: {
    getPromotion() {
      API.getPromotionEnabled({}, (resp) => {
        if (resp.data) {
          this.isPromotionEnable = true;
          MobileAPI.getTestUrl(
            {
              userId: this.userDetails.UserId,
            },
            (response) => {
              this.testUrl = response.data?.Url;
            },
          );
        }
      });
    },
    getMyProfile() {
      this.$router.push({ name: "MyProfile" });
    },
    takeTest() {
      window.open(this.testUrl);
    },
    formateTime(value) {
      if (value) {
        return moment(value).format("DD MMMM YYYY");
      }
      return 0;
    },
    setInitialPageLoadDetails(user, courseId, classId) {
      const data = {
        userId: user.UserId,
        courseId,
        classId,
      };
      this.$store.dispatch("showLoader", true);
      const userData = {
        UserId: user.UserId,
        LastRefreshTime: null,
      };
      if (localStorage.getItem("DashboardRefreshTime") != null) {
        userData.LastRefreshTime = moment(localStorage.getItem("DashboardRefreshTime")).format(
          "YYYY-MM-DD HH:mm:ss",
        );
      }
      MobileAPI.getDashboardUpdateNeeded(userData, (apiResponse) => {
        const apiData = apiResponse.data;
        let isUpdateNeeded = true;
        if (apiData != null) {
          isUpdateNeeded = Boolean(apiData[0].UpdateNeeded);
          if (isUpdateNeeded === false) {
            // localStorage.g("SLToken", to.query.token);
            const response = JSON.parse(localStorage.getItem("DashboardData"));
            this.getNewStudentDashboardsub = response.data.subjects;
            if (response.data.recommendations) {
              this.getNewStudentDashboardpra = response.data.recommendations.Practice
                ? response.data.recommendations.Practice
                : [];
              this.getNewStudentDashboardst = response.data.recommendations.SelfTest
                ? response.data.recommendations.SelfTest
                : [];
              this.getNewStudentDashboardLearn = response.data.recommendations.Learn
                ? response.data.recommendations.Learn
                : [];
              this.filteredLearnData = response.data.recommendations.Learn
                ? response.data.recommendations.Learn
                : [];
            }
            this.getLeaderBoardData = response.data.leaderboard ? response.data.leaderboard : [];
            this.getInsituteTest = response.data.instituteTests ? response.data.instituteTests : [];
            this.getAssignment = response.data.assignments ? response.data.assignments : [];
            this.subjectList = this.getNewStudentDashboardpra;
            this.selfList = this.getNewStudentDashboardst;
            this.selfTestSubjectList = this.getNewStudentDashboardst;
            this.assignmentSubjectList = this.getAssignment;
            this.getNewStudentDashboardLearn.forEach((chapter) => {
              if (!this.allchapters.includes(chapter.chapterId)) {
                this.allchapters.push(chapter.chapterId);
              }
            });
            // MobileAPI.getVimeoVideo({
            //   CourseId: this.userCourseId,
            //   ChapterIds: this.allchapters.toString(),
            // }, (response1) => {
            //   this.listofVimeoVideos = response1.data;
            //   this.videos = this.listofVimeoVideos;
            // });
            this.allPractice();
            this.allassignments();
            this.allSelfTest();
            this.allvideo();
            this.showDashboard = true;
            this.$store.dispatch("showLoader", false);
          } else {
            // eslint-disable-next-line no-unused-expressions
            MobileAPI.getNewStudentDashboard(data, (response) => {
              localStorage.setItem("DashboardRefreshTime", moment());
              localStorage.setItem("DashboardData", JSON.stringify(response));
              this.$store.dispatch("showLoader", false);
              this.getNewStudentDashboardsub = response.data.subjects ? response.data.subjects : [];
              if (response.data.recommendations) {
                this.getNewStudentDashboardpra = response.data.recommendations.Practice
                  ? response.data.recommendations.Practice
                  : [];
                this.getNewStudentDashboardst = response.data.recommendations.SelfTest
                  ? response.data.recommendations.SelfTest
                  : [];
                this.getNewStudentDashboardLearn = response.data.recommendations.Learn
                  ? response.data.recommendations.Learn
                  : [];
                this.filteredLearnData = response.data.recommendations.Learn
                  ? response.data.recommendations.Learn
                  : [];
              }
              this.getLeaderBoardData = response.data.leaderboard ? response.data.leaderboard : [];
              this.getInsituteTest = response.data.instituteTests
                ? response.data.instituteTests
                : [];
              this.getAssignment = response.data.assignments ? response.data.assignments : [];
              this.subjectList = this.getNewStudentDashboardpra;
              this.selfList = this.getNewStudentDashboardst;
              this.selfTestSubjectList = this.getNewStudentDashboardst;
              this.assignmentSubjectList = this.getAssignment;
              this.getNewStudentDashboardLearn.forEach((chapter) => {
                if (!this.allchapters.includes(chapter.chapterId)) {
                  this.allchapters.push(chapter.chapterId);
                }
              });
              //   MobileAPI.getVimeoVideo({
              //     CourseId: this.userCourseId,
              //     ChapterIds: this.allchapters.toString(),
              //   }, (response1) => {
              //     this.listofVimeoVideos = response1.data;
              //     this.videos = this.listofVimeoVideos;
              //   });
              this.allPractice();
              this.allassignments();
              this.allSelfTest();
              this.allvideo();
              this.showDashboard = true;
            });
          }
          MobileAPI.getStudentAvgReport(this.userDetails.UserId, 0, (response) => {
            this.avgreport = response.data;
            this.avreportlength = response.data.length;
          });
          MobileAPI.getCoinActivity(this.userDetails.UserId, (response2) => {
            this.getCoinActivity = response2.data;
            if (this.getCoinActivity !== null) {
              this.filteredCoins = this.getCoinActivity.filter((a) => a.Type === "ActivityGrouped");
              this.totalFilteredCoins = this.getCoinActivity.filter(
                (obj) => obj.Type === "TotalCoins",
              );
            }
          });
        }
      });
    },
    updateParentRequest(status) {
      // this.$store.dispatch("showLoader", true);
      API.updateParentRequest(
        {
          parentStudentLinkId: this.parentRequest.ParentStudentLinkId,
          isApproved: status,
        },
        () => {
          this.getParentRequests();
        },
      );
    },
    getParentRequests() {
      API.parentRequestsForStudent({}, (response) => {
        this.parentRequest = response.data;
        if (this.parentRequest == null || this.parentRequest === "") {
          this.parentRequest = {
            ParentStudentLinkId: 0,
            Name: "",
            EmailId: "",
            Mobile: "",
            CreatedOn: "",
            Date: "",
          };
        }
        if (this.parentRequest.ParentStudentLinkId > 0) {
          this.showParentCard = true;
        } else {
          this.showParentCard = false;
        }
      });
    },
    viewAssignment() {
      const baseUrl = this.userDetails.DomainName;
      const assignmentLink = `${baseUrl}/Homework`;
      window.open(assignmentLink);
    },
    reportCard() {
      this.showClass = false;
      this.showReport = true;
    },
    classCard() {
      this.showClass = true;
      this.showReport = false;
    },
    onClassChange(classId, className) {
      this.userClassId = classId;
      this.dashboardClassId = this.userClassId;
      this.dashboardClassName = className;
      const data = {
        UserId: this.userDetails.UserId,
        ClassId: this.userClassId,
      };
      this.updateClassofSTudent(data);
      this.setInitialPageLoadDetails(this.userDetails, this.userCourseId, this.userClassId);
    },
    updateClassofSTudent(data) {
      MobileAPI.updateClass(data, (response) => {
        this.class = response.data;
        this.showSelectClassModal = false;
      });
    },
    startNewSelfTest() {
      this.$router.push({ path: "/student/self-test" });
    },
    selectNewVideo() {
      this.$router.push({ name: "LearnVideos" });
    },
    prevSlide() {
      this.showImg -= 1;
      if (Number(this.showImg) <= -1) {
        this.showImg = Number(this.avgreport.length) - 1;
      }
    },
    nextSlide() {
      this.showImg += 1;
      if (this.showImg === this.avreportlength) {
        this.showImg = 0;
      }
    },
    startPractice(selectedItem) {
      this.$store.dispatch("showLoader", true);
      const user = this.$store.getters.user;
      this.userCourseId = Number(this.dashboardCourseId);
      if (this.userCourseId !== 1) {
        const data = {
          courseChapterIds: selectedItem.courseChapterId,
          questionTypeIds: "1,3,4,5",
          questionLevelIds: "1,2",
          isPastYear: 0,
        };
        MobileAPI.getPracticeQuestion(user.UserId, data, (response) => {
          this.practiceQuestionList = response.data;
          if (this.practiceQuestionList.questionsList.length !== 0) {
            this.questionList = this.practiceQuestionList.questionsList;
            this.kscList = this.practiceQuestionList.kscClusterNames;
            this.chapterList = this.practiceQuestionList.chapterNames;
            this.subjectList = this.practiceQuestionList.subjectDetails;
            this.messages = this.practiceQuestionList.messages;
            this.prompts = this.practiceQuestionList.prompts;
            this.requestBody = {
              subjectId: Number(selectedItem.subjectId),
              topicId: 0,
              difficultyLevel: 1,
              courseId: Number(this.dashboardCourseId),
              courseChapterIdList: [selectedItem.courseChapterId],
              chapterIdList: [selectedItem.chapterId],
            };
            MobileAPI.startNewPracticeSession(this.requestBody, (practice) => {
              this.$store.dispatch("showLoader", false);
              if (practice.responseCode === 200) {
                this.USessionId = practice.data[0].UserTestSessionId;
                if (practice.responseCode === 200 && practice.data[0].UserTestSessionId > 0) {
                  this.$router.push({
                    name: "PracticeSession",
                    params: {
                      questionList: JSON.stringify(this.questionList),
                      kscList: JSON.stringify(this.kscList),
                      chapterList: JSON.stringify(this.chapterList),
                      subjectList: JSON.stringify(this.subjectList),
                      messages: JSON.stringify(this.messages),
                      prompts: JSON.stringify(this.prompts),
                      UserTestSessionId: this.USessionId,
                      coureId: this.dashboardCourseId,
                      subjectName: selectedItem.subjectName,
                      subjectId: selectedItem.subjectId,
                      className: this.dashboardClassName,
                      chapters: selectedItem.chapterName,
                      isSubjective: 0,
                      isPastYear: 0,
                    },
                  });
                }
              }
            });
          }
        });
      } else if (this.userCourseId === 1) {
        const data = {
          courseChapterIds: selectedItem.courseChapterId,
          questionTypeIds: "1,3,4,5",
          questionLevelIds: "1",
          isPastYear: 0,
        };
        MobileAPI.getPracticeQuestion(user.UserId, data, (response) => {
          this.practiceQuestionList = response.data;
          if (this.practiceQuestionList.questionsList.length !== 0) {
            this.questionList = this.practiceQuestionList.questionsList;
            this.kscList = this.practiceQuestionList.kscClusterNames;
            this.chapterList = this.practiceQuestionList.chapterNames;
            this.subjectList = this.practiceQuestionList.subjectDetails;
            this.messages = this.practiceQuestionList.messages;
            this.prompts = this.practiceQuestionList.prompts;
            this.requestBody = {
              subjectId: Number(selectedItem.subjectId),
              topicId: 0,
              difficultyLevel: 1,
              courseId: Number(this.dashboardCourseId),
              courseChapterIdList: [selectedItem.courseChapterId],
              chapterIdList: [selectedItem.chapterId],
            };
            MobileAPI.startNewPracticeSession(this.requestBody, (practice) => {
              this.$store.dispatch("showLoader", false);
              if (practice.responseCode === 200) {
                this.USessionId = practice.data[0].UserTestSessionId;
                if (practice.responseCode === 200 && practice.data[0].UserTestSessionId > 0) {
                  this.$router.push({
                    name: "PracticeSession",
                    params: {
                      questionList: JSON.stringify(this.questionList),
                      kscList: JSON.stringify(this.kscList),
                      chapterList: JSON.stringify(this.chapterList),
                      subjectList: JSON.stringify(this.subjectList),
                      messages: JSON.stringify(this.messages),
                      prompts: JSON.stringify(this.prompts),
                      UserTestSessionId: this.USessionId,
                      coureId: this.dashboardCourseId,
                      subjectName: selectedItem.subjectName,
                      subjectId: selectedItem.subjectId,
                      className: this.dashboardClassName,
                      chapters: selectedItem.chapterName,
                      isSubjective: 0,
                      isPastYear: 0,
                    },
                  });
                }
              }
            });
          }
        });
      }
    },
    startNewPractice() {
      this.$router.push({ name: "PracticeHome" });
    },
    joinClass(Url) {
      window.open(Url);
    },
    goVideo() {
      this.$router.push({ name: "LearnVideos" });
    },
    goSubjectCoverage(subjectData) {
      this.dashboardSubjectId = subjectData.subjectId;
      const subjectCoverageParams = {
        dashboardUserDetails: this.dashboardUserDetails,
        dashboardClassId: this.dashboardClassId,
        dashboardCourseId: this.dashboardCourseId,
        dashboardSubjectId: this.dashboardSubjectId,
        dashboardSubjectName: subjectData.subjectName,
        dashboardSubjectIcon: subjectData.subjectIcon,
        dashboardUserId: this.userDetails.UserId,
        dashboardDomainName: this.userDetails.DomainName,
        dashboardClassName: this.dashboardClassName,
      };
      localStorage.setItem("SubjectCoverageParams", JSON.stringify(subjectCoverageParams));
      this.$router.push({ name: "SubjectCoverage", params: subjectCoverageParams });
    },
    goPractice(subjectData) {
      this.dashboardSubjectId = subjectData.subjectId;
      this.$router.push({
        name: "PracticeHome",
        params: {
          dashboardUserDetails: this.dashboardUserDetails,
          dashboardClassId: this.dashboardClassId,
          dashboardClassName: this.dashboardClassName,
          dashboardCourseId: this.dashboardCourseId,
          dashboardSubjectId: this.dashboardSubjectId,
          dashboardSubjectName: subjectData.subjectName,
          dashboardSubjectIcon: subjectData.subjectIcon,
          dashboardUserId: this.userDetails.UserId,
          dashboardDomainName: this.userDetails.DomainName,
        },
      });
    },
    startInstituteTest() {
      this.$router.push({ name: "SelectInstituteTest" });
    },
    startSelfTestFull() {
      // TODO: This should prefill the self test form
      this.$router.push({ path: "/student/self-test" });
    },

    startSelfTest() {
      this.$router.push({ path: "/student/self-test" });
    },
    allPractice() {
      this.isActive = true;
      this.activeIndex = null;
      this.subjectList = this.getNewStudentDashboardpra;
    },
    getsubdata(subject, index) {
      this.isActive = false;
      this.activeIndex = index;
      this.SelectedSubjectid = subject.subjectId;
      const data = {
        userId: this.userDetails.UserId,
        courseId: this.userCourseId,
        classId: this.userClassId,
      };
      const user = this.$store.getters.user;
      const userData = {
        UserId: user.UserId,
        LastRefreshTime: null,
      };
      if (localStorage.getItem("DashboardRefreshTime") != null) {
        userData.LastRefreshTime = moment(localStorage.getItem("DashboardRefreshTime")).format(
          "YYYY-MM-DD HH:mm:ss",
        );
      }
      MobileAPI.getDashboardUpdateNeeded(userData, (apiResponse) => {
        const apiData = apiResponse.data;
        let isUpdateNeeded = true;
        if (apiData != null) {
          isUpdateNeeded = Boolean(apiData[0].UpdateNeeded);
          if (isUpdateNeeded === false) {
            // localStorage.g("SLToken", to.query.token);
            const response = JSON.parse(localStorage.getItem("DashboardData"));
            this.getNewStudentDashboardsub = response.data.subjects;
            if (response.data.recommendations) {
              this.getNewStudentDashboardpra = response.data.recommendations.Practice
                ? response.data.recommendations.Practice
                : [];
            }
          } else {
            MobileAPI.getNewStudentDashboard(data, (response) => {
              this.getNewStudentDashboardsub = response.data.subjects;
              if (response.data.recommendations) {
                this.getNewStudentDashboardpra = response.data.recommendations.Practice
                  ? response.data.recommendations.Practice
                  : [];
              }
            });
          }
        }
      });
      if (this.SelectedSubjectid !== "") {
        this.subjectList = this.getNewStudentDashboardpra.filter(
          (a) => a.subjectId === this.SelectedSubjectid,
        );
      } else {
        this.subjectList = this.getNewStudentDashboardpra;
      }
    },
    allassignments() {
      this.isActive1 = true;
      this.activeIndex1 = null;
      this.assignmentSubjectList = this.getAssignment;
    },
    getAssignmentData(subject, index) {
      this.SelectedSubjectid = subject.subjectId;
      this.isActive1 = false;
      this.activeIndex1 = index;
      const data = {
        userId: this.userDetails.UserId,
        courseId: this.userCourseId,
        classId: this.userClassId,
      };
      const user = this.$store.getters.user;
      const userData = {
        UserId: user.UserId,
        LastRefreshTime: null,
      };
      if (localStorage.getItem("DashboardRefreshTime") != null) {
        userData.LastRefreshTime = moment(localStorage.getItem("DashboardRefreshTime")).format(
          "YYYY-MM-DD HH:mm:ss",
        );
      }
      MobileAPI.getDashboardUpdateNeeded(userData, (apiResponse) => {
        const apiData = apiResponse.data;
        let isUpdateNeeded = true;
        if (apiData != null) {
          isUpdateNeeded = Boolean(apiData[0].UpdateNeeded);
          if (isUpdateNeeded === false) {
            // localStorage.g("SLToken", to.query.token);
            const response = JSON.parse(localStorage.getItem("DashboardData"));
            this.getNewStudentDashboardsub = response.data.subjects;
            this.getNewStudentDashboardpra = response.data.recommendations.Practice;
          } else {
            MobileAPI.getNewStudentDashboard(data, (response) => {
              this.getNewStudentDashboardsub = response.data.subjects;
              this.getAssignment = response.data.assignments;
            });
          }
        }
      });
      if (this.SelectedSubjectid !== "") {
        this.assignmentSubjectList = this.getAssignment.filter(
          (a) => a.subjectId === this.SelectedSubjectid,
        );
      } else {
        this.assignmentSubjectList = this.getAssignment;
      }
    },
    startAssignment(Chapter) {
      const assignmentData = {
        ExamId: Chapter.assignmentId,
        UserId: this.userDetails.UserId,
      };
      const dateTime = new Date().toISOString();
      this.$store.dispatch("showLoader", true);
      MobileAPI.startExam(
        {
          ExamId: assignmentData.ExamId,
          UserId: assignmentData.UserId,
          StartedOn: dateTime,
          TerminalId: 0,
        },
        (response) => {
          if (response.responseCode === 200) {
            this.ExamSessionId = response.data[0].ExamSessionId;
            this.$router.push({
              name: "ConductTest",
              params: { testUserId: this.ExamSessionId, testType: 4 },
            });
          }
          this.$store.dispatch("showLoader", false);
        },
      );
    },
    allSelfTest() {
      this.isActive2 = true;
      this.activeIndex2 = null;
      this.selfList = this.getNewStudentDashboardst;
    },
    getselfdata(subject, index) {
      this.SelectedSubjectid = subject.subjectId;
      this.isActive2 = false;
      this.activeIndex2 = index;
      const data = {
        userId: this.userDetails.UserId,
        courseId: this.userCourseId,
        classId: this.userClassId,
      };
      const user = this.$store.getters.user;
      const userData = {
        UserId: user.UserId,
        LastRefreshTime: null,
      };
      if (localStorage.getItem("DashboardRefreshTime") != null) {
        userData.LastRefreshTime = moment(localStorage.getItem("DashboardRefreshTime")).format(
          "YYYY-MM-DD HH:mm:ss",
        );
      }
      MobileAPI.getDashboardUpdateNeeded(userData, (apiResponse) => {
        const apiData = apiResponse.data;
        let isUpdateNeeded = true;
        if (apiData != null) {
          isUpdateNeeded = Boolean(apiData[0].UpdateNeeded);
          if (isUpdateNeeded === false) {
            // localStorage.g("SLToken", to.query.token);
            const response = JSON.parse(localStorage.getItem("DashboardData"));
            this.getNewStudentDashboardsub = response.data.subjects;
            this.getNewStudentDashboardst = response.data.recommendations.SelfTest;
          } else {
            MobileAPI.getNewStudentDashboard(data, (response) => {
              this.getNewStudentDashboardsub = response.data.subjects;
              this.getNewStudentDashboardst = response.data.recommendations.SelfTest;
            });
          }
        }
      });
      if (this.SelectedSubjectid !== "") {
        this.selfList = this.getNewStudentDashboardst.filter(
          (a) => a.subjectId === this.SelectedSubjectid,
        );
      } else {
        this.selfList = this.getNewStudentDashboardst;
      }
    },
    allvideo() {
      this.isActive3 = true;
      this.activeIndex3 = null;
      this.getNewStudentDashboardLearn.forEach((chapter) => {
        if (!this.allchapters.includes(chapter.chapterId)) {
          this.allchapters.push(chapter.chapterId);
        }
      });
      this.filteredLearnData = this.getNewStudentDashboardLearn;
    },
    videoselect(subject, index) {
      this.SelectedSubjectid = subject.subjectId;
      this.isActive3 = null;
      this.activeIndex3 = index;
      this.listofChapters = this.getNewStudentDashboardLearn.filter(
        (a) => a.subjectId === this.SelectedSubjectid,
      );
      this.filteredLearnData = this.getNewStudentDashboardLearn.filter(
        (a) => a.subjectId === this.SelectedSubjectid,
      );
    },
    changeImgColor(color) {
      if (color === "#8BC32E") {
        return "filter : invert(65%) sepia(68%) saturate(500%) hue-rotate(38deg) brightness(98%) contrast(79%);";
      }
      if (color === "B03E3E") {
        return "filter: invert(31%) sepia(9%) saturate(6248%) hue-rotate(323deg) brightness(104%) contrast(87%);";
      }
      return color;
    },
    watchVideo(videoUrl) {
      this.avVideoUrl = videoUrl;
      this.showAvVideo = true;
    },
  },
  computed: {
    fullheight() {
      return `height:${this.height}%`;
    },
  },
};
</script>

<style scoped>
/*Custom CSS*/
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("../../../node_modules/@egjs/vue3-flicking/dist/flicking-inline.css");

.student-dashboard {
  font-family: "Open Sans" !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+/Edge */
  user-select: none;
  /* Standard */
  margin: 0;
}

@font-face {
  font-family: PackMan;
  src: url(../../font/pac-font.ttf);
}

* {
  box-sizing: border-box;
}

.dark-text {
  color: #333333 !important;
}

.dark-light-text {
  color: #333333 !important;
  opacity: 0.4;
}

.black-text {
  color: #000000 !important;
}

.skyblue-text {
  color: #3751ff !important;
}

.relative {
  position: relative !important;
}

.modal-button.selected {
  margin-top: 10px;
  background: #f4e7ff;
  border: 1px solid #642c90;
  border-radius: 6px;
  width: 128px;
  height: 50px;
}

.modal-button {
  margin-top: 10px;
  background: #f4e7ff;
  border: 1px solid #642c90;
  cursor: pointer;
  border-radius: 6px;
  width: 128px;
  height: 50px;
}

.modal-select {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */

  text-align: center;

  color: #642c90;
}

.flex-around {
  justify-content: space-around !important;
}

.absolute {
  position: absolute !important;
}

.flex {
  display: flex !important;
}

.flex-between {
  justify-content: space-between;
}

.flex-column {
  flex-direction: column;
}

.flex-center {
  justify-content: center;
}

.items-end {
  align-items: flex-end;
}

.items-baseline {
  align-items: baseline;
}

.mt-33 {
  margin-top: 33px;
}

.items-center {
  align-items: center;
}

.purple-text {
  color: #642c90 !important;
}

.white-bgc {
  background: #fff;
}

.inst-bgc {
  background: linear-gradient(98.34deg, #e9ccff 11.31%, #f4e6ff 88.13%);
}

.lightPink-bgc {
  background: #ffecdb;
}

.lightGreen-bgc {
  background: #ebffe7;
}

.test-bgc {
  background: #dceaff;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-9 {
  font-size: 9px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-9 {
  font-size: 9px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.m-0 {
  margin: 0;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin-left: 5px;
}

.scroll-horizontal {
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.scroll-verticle {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 175px;
  white-space: nowrap;
}

.scroll-verticle::-webkit-scrollbar {
  width: 0px;
}

.scroll-verticle::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 5px;
}

.scroll-verticle::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

.recommend-box .yellow-btn {
  opacity: 0;
  visibility: hidden;
}

.recommend-box:hover .yellow-btn {
  opacity: 1;
  visibility: visible;
}

.recommend-box .practice-image:hover {
  cursor: pointer;
}

.recommend-top .scroll-horizontal::-webkit-scrollbar {
  height: 3px;
}

.scroll-horizontal::-webkit-scrollbar {
  height: 3px;
}

.scroll-horizontal::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 5px;
}

.scroll-horizontal::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

.scroll-verticle {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 175px;
  white-space: nowrap;
}

.scroll-verticle::-webkit-scrollbar {
  width: 3px;
}

.scroll-verticle::-webkit-scrollbar-track {
  background-color: #ececec;
  border-radius: 5px;
}

.scroll-verticle::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 5px;
}

/*Leaderboard & Rewards Section*/
.leaderboard_reward-section {
  min-width: 216px;
  margin-right: 15px;
}

.leader-avatar {
  width: 20px !important;
  height: 20px !important;
  font-size: 9px !important;
  /* background: #FC8686 !important; */
  color: white !important;
  margin-right: 10px;
  font-family: "PackMan" !important;
}

.modal-dashbody {
  height: 510px;
  width: 540px;
  overflow-y: hidden !important;
}

.browser-default {
  height: 32px;
  width: 150px;
  /* border: none !important; */
}

/*
  .video .recommendation-box,
  .video .main-recommendation-box {
      width: 145px !important;
  }*/

.reward-header {
  height: 69px;
  background: #75447c;
  border-radius: 5px 5px 0px 0px;
  position: relative;
  padding: 15px;
}

.reward-body {
  padding: 10px 15px 15px;
}

.reward-list {
  margin-bottom: 10px;
}

.reward-list li {
  border-bottom: 1px solid #f7f7f7;
  padding: 5px 0;
}

.reward-list li img {
  position: relative;
  top: 3px;
}

.subject-image {
  position: absolute;
  top: 10px;
  right: -3px;
  opacity: 0.1;
  width: 47px;
}

.class-image {
  position: absolute;
  bottom: -10px;
  left: 171px;
  opacity: 0.2;
}

.test-image {
  position: absolute;
  bottom: -10px;
  right: 0;
  opacity: 0.2;
}

.practice-image {
  position: absolute;
  top: 36px;
  right: 20px;
  opacity: 0.1;
  height: 47px;
}

.yellow-btn {
  width: 85px;
  height: 24px;
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
  border-radius: 3px;
  border: none;
  color: white;
  cursor: pointer;
  /* font-family: 'Open Sans'; */
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  white-space: nowrap;
  z-index: 20;
}

.gray-btn {
  max-width: 103px;
  min-width: 42px;
  margin-bottom: 5px !important;
  height: 22px;
  background: #f8f8f8;
  border: none;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  text-align: center;
  color: #333333;
  margin: 0 8px;
  opacity: 0.65;
  cursor: pointer;
  white-space: nowrap;
}

.gray-btn:first-child,
.yellow-btn:first-child {
  margin-left: 0;
}

.gray-btn.active {
  background: #f0f2ff;
  border: 1px solid #d6daf7;
  color: #000000;
  opacity: 1;
  font-weight: 600;
}

.gray-btn.selected {
  background: #f0f2ff;
  border: 1px solid #d6daf7;
  color: #000000;
  font-weight: 600;
}

.level-btn {
  min-width: 34px;
  max-width: 55px;
  height: 16px;
  border-radius: 8px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  color: #ffffff;
  border: none;
  line-height: 0;
  cursor: pointer;
}

.hard {
  background: #ff2633;
}

.easy {
  background: #37bc16;
}

.medium {
  background: #f59e00;
}

.top-header {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #333333;
  margin-bottom: 10px;
}

.card-shadow {
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
}

.card-border {
  border: 1px solid #e3e3e3;
}

.yellow-gradient {
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
}

.card-radius {
  border-radius: 5px;
}

.card-radius-2 {
  border-radius: 6px;
}

/*Main CSS*/
.subject-section {
  width: 355px;
  padding: 0;
}

.subject-content {
  display: grid;
  grid-template-columns: 170px 170px;
  grid-gap: 15px;
}

.subject-box {
  max-width: 170px;
  height: 101px;
  border: 1px solid #e3e3e3;
  padding: 16px 15px;
  overflow: hidden;
  cursor: pointer;
}

.subject-box::before {
  left: 0;
  top: 0;
  position: absolute;
  content: "";
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  z-index: -1;
}

/* .subject-box:nth-child(1)::before {
      right: -10px;
      background: url('../../newSubjectIcons/Maths.svg') no-repeat;
  }
  .subject-box:nth-child(2)::before {
      background: url('../../assets/Science.svg') no-repeat;
      bottom: -36px;
      right: -6px;
  }
  .subject-box:nth-child(3)::before {
      background: url('../../assets/social-science 1.svg') no-repeat;
          bottom: -41px;
      right: -18px;
  }
  .subject-box:nth-child(4)::before {
      background: url('../../assets/hindi-letter 1.svg') no-repeat;
      bottom: -38px;
          right: -16px;
  }
  .subject-box:nth-child(5)::before {
      background: url('../../assets/english 1.svg') no-repeat;
          bottom: -32px;
      right: -16px;
  }
  .subject-box:nth-child(6)::before {
      background: url('../../assets/computer 1.svg') no-repeat;
          bottom: -36px;
  }

  .subject-box:nth-child(2)::before {
      background: url('../../newSubjectIcons/Science.svg') no-repeat;
      bottom: -36px;
      right: -6px;
  }
  .subject-box:nth-child(4)::before {
      background: url('../../newSubjectIcons/Social Science.svg') no-repeat;
      bottom: -36px;
      right: -6px;
  } */

.subject-box .sub-name::before {
  position: absolute;
  content: "";
  top: -5px;
  left: -15px;
  background: #642c90;
  border-radius: 0px 4px 4px 0px;
  width: 4px;
  height: 26px;
}

.subject-box .accuracy {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  margin-top: 11px;
  margin-bottom: 4px;
  align-items: center;
}

.subject-box p {
  color: #333333;
  opacity: 0.4;
  margin-bottom: 10px;
}

.hover-box {
  border-radius: 0px 0px 5px 5px;
  width: 170px;
  height: 53px;
  z-index: 99;
  left: 0;
  bottom: -100px;
  padding: 17px;
  line-height: normal;
  transition: all 0.3s ease-in;
}

.subject-box:hover .hover-box {
  bottom: 0px;
}

.subject-box:hover .sub-btn.yellow-btn {
  opacity: 0;
}

/*Test*/

.take-test {
  padding: 15px;
  width: 355px;
  height: 92px;
  margin-top: 15px;
}

.take-test {
  padding: 15px;
  width: 355px;
  height: 92px;
  margin: 15px 0 0;
}

.take-test p {
  /* font-family: 'Open Sans'; */
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  color: #333333;
  opacity: 0.7;
  margin-top: 9px;
}

/*New Activity */
.new-activity h1 {
  padding: 20px 0 17px;
  margin-bottom: 0;
}

.slideshow-container {
  min-width: 355px;
  height: 203px;
  background: #5a5fe6;
  box-shadow: 4px 4px 10px rgb(100 44 144 / 6%);
  border-radius: 5px;
}

.slideshow1-container {
  min-width: 355px;
  height: 230px;
  background: #56cd9e;
  /* background: #41d71c; */
  /* background: #21700D;#329d17 */
  padding-bottom: 10px;
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

.dot {
  cursor: pointer;
  height: 15px;
  width: 46px;
  background-color: #bbb;
  display: inline-block;
  transition: background-color 0.6s ease;
  position: relative;
  width: 30px;
  height: 4px;
  top: 11px;
  margin-right: 10px;
  opacity: 0.5;
  border-radius: 2px;
}

.active,
.dot:hover {
  background: #642c90;
}

.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}

/*Recommendation Section */
.recommend-section {
  max-width: 570px;
  min-width: 220px;
  padding: 0 15px;
}

.recommend-box {
  width: 170px;
  height: 80px;
  border-radius: 6px;
}

.recommendation {
  border-bottom: 1px solid #f7f7f7;
  padding-bottom: 10px;
}

.recommendation:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.recommendation-content {
  margin-top: 15px;
}

.recommendation-content .main-recommendation-box {
  width: 170px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.recommendation-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.recommendation-box .progress {
  position: absolute;
  transform: rotate(180deg);
  width: 4px;
  height: 56px;
  background: #f7f7f7;
  right: 9px;
  top: 7%;
  box-shadow: inset 1px 2px 1px rgba(0, 0, 0, 0.25);
}

.progress-bar.transparent {
  background: transparent;
}

.progress-bar.red {
  background: linear-gradient(180deg, #fd7504 0%, #ff3636 100%);
}

.progress-bar.yellow {
  background: yellow;
}

.progress-bar.blue {
  background: blue;
}

.progress-bar.green {
  background: linear-gradient(180deg, #37bc16 0%, #91bc16 100%);
}

.assignment .yellow-btn {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.assignment .recommendation-box:hover .yellow-btn {
  opacity: 1;
}

.practice .yellow-btn {
  opacity: 0;
  transition: all 0.3s ease-out;
}

.practice .recommendation-box:hover .yellow-btn {
  opacity: 1;
}

.tests .test-btn {
  width: 64px;
  height: 14px;
  border: none;
  background: #ccb6dc;
  cursor: pointer;
  padding: 7px 3px;
  line-height: 0px;
  opacity: 0;
  transition: all 0.3s ease-out;
}

.tests .self-btn {
  width: 64px;
  height: 14px;
  border: none;
  background: #c0d9ff;
  cursor: pointer;
  padding: 7px 3px;
  line-height: 0px;
  opacity: 0;
  transition: all 0.3s ease-out;
}

.tests .recommendation-box:hover .test-btn {
  opacity: 1;
}

/*Leaderboard & Rewards Section*/
.leaderboard_reward-section {
  min-width: 216px;
  margin-right: 15px;
}

/*
  .leaderboard {
      height: 307px;
      margin-bottom: 55px;
  }*/

.leader-avatar {
  width: 20px !important;
  height: 20px !important;
  font-size: 9px !important;
  /* background: #FC8686 !important; */
  color: white !important;
  margin-right: 10px;
}

.reward-header {
  height: 69px;
  background: #75447c;
  border-radius: 5px 5px 0px 0px;
  position: relative;
  padding: 15px;
}

.reward-header:after {
  position: absolute;
  content: "";
  background: url("../../assets/Group91803.svg") no-repeat;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
  width: 48px;
  height: 54px;
  z-index: 1;
}

.reward-list li {
  border-bottom: 1px solid #f7f7f7;
  padding: 5px 0;
}

.reward-list li img {
  position: relative;
  top: 3px;
}

.tests .recommendation-box:hover .self-btn {
  opacity: 1;
}

/*.earn-modal {
      width: 1172px;
  }*/
.earn-head {
  position: relative;
  width: 100%;
  background: #f3f5fc;
  padding: 20px 0;
  border-radius: 5px 5px 0 0;
}

.take-test-card {
  max-width: 570px;
  width: 100%;
  margin-bottom: 20px;
  background-color: #642c90;
  border-radius: 5px;
}

.test-card {
  margin-top: 10px;
  min-height: 90px;
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid #e0e4f0;
}

.test-button {
  width: 85px;
  height: 24px;
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
  border-radius: 3px;
  border: none;
  color: white;
  cursor: pointer;
  /* font-family: 'Open Sans'; */
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  white-space: nowrap;
  z-index: 20;
}

/*
  .dashboard{
      flex-wrap: no-wrap;
  }*/
/*Media Query */
@media screen and (max-width: 1200px) {
  /*.hw-to-earn-content {
          grid-template-columns: repeat(1, 1fr) !important;
      }

      .earn-modal {
          width: 720px;
      }*/

  .hw-to-earn-content {
    padding: 45px !important;
  }
}

@media screen and (max-width: 1076px) {
  .earn-card {
    width: 350px !important;
  }
}

@media screen and (max-width: 852px) {
  .earn-card {
    width: 320px !important;
  }
}

@media screen and (max-width: 992px) {
  .subject-section {
    padding: 0 0.75rem !important;
  }

  .recommend-section {
    padding: 0px 25px !important;
  }

  .dashboard {
    justify-content: center;
  }

  .take-test-card {
    margin: 10px 20px !important;
  }
}

@media screen and (max-width: 900px) {
  .dashboard {
    flex-wrap: wrap;
  }

  .leaderboard_reward-section {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 15px;
    flex-direction: column;
    margin-right: 0;
  }

  .leaderboard,
  .reward {
    max-width: 570px;
    padding: 0px 15px !important;
    width: 100%;
  }

  .reward {
    margin-bottom: 20px;
  }

  .recommend-section {
    padding: 0px 15px !important;
  }

  .new-activity {
    margin-bottom: 0 !important;
  }

  .subject-section {
    padding: 0 !important;
  }
}

@media screen and (max-width: 780px) {
  .hw-to-earn-content {
    grid-template-columns: repeat(1, 1fr) !important;
    padding: 28px !important;
    grid-gap: 25px !important;
  }

  .earn-modal {
    width: 520px;
  }

  .earn-card {
    width: 100% !important;
  }
}

@media screen and (max-width: 600px) {
  .videoModal {
    width: 100% !important;
    height: auto !important;
    padding: 10px 0 !important;
  }
}

@media screen and (max-width: 550px) {
  /*.earn-modal {
          width: 400px;
      }

      .hw-to-earn-content {
          padding: 10px !important;
      }*/
  .earn-card {
    width: 320px !important;
  }

  .earn-modal {
    width: auto !important;
  }
}

@media screen and (max-width: 430px) {
  /*.earn-modal {
          width: 300px;
      }*/
}

@media screen and (max-width: 386px) {
  .hw-to-earn-content {
    padding: 8px 5px !important;
  }
}

@media screen and (max-width: 375px) {
  .no-padding {
    padding: 0 0.75rem !important;
  }

  .subject-section {
    padding: 0 25px !important;
  }

  .report-video {
    width: 100% !important;
    padding: 0 5px;
  }

  .subject-content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  .subject-section {
    padding: 0;
  }

  .subject-box {
    max-width: 375px;
    margin-bottom: 10px;
  }

  .hover-box {
    width: 100%;
  }

  .take-test {
    max-width: 100%;
    height: max-content;
    margin: 0;
    margin-bottom: 2px;
  }

  .parent-img {
    width: 100px;
    right: 10px;
  }

  .parent-img {
    width: 100px;
    right: 10px;
  }

  .slideshow-container {
    min-width: auto;
    padding: 2px 0;
    height: auto;
  }
}

@media screen and (max-width: 338px) {
  .earn-modal {
    width: 300px !important;
  }

  .earn-card {
    width: 290px !important;
  }
}

.vimeo-video {
  position: absolute;
  top: 0;
  height: 80px;
  width: 150px;
  /* padding: 1px; */
  border: none;
  border-radius: 6px;
}

button#one:focus {
  background: #f0f2ff;
  border: 1px solid #d6daf7;
  color: #000000;
  font-weight: 600;
}

button#two:focus {
  background: #f0f2ff;
  border: 1px solid #d6daf7;
  color: #000000;
  font-weight: 600;
}

button#three:focus {
  background: #f0f2ff;
  border: 1px solid #d6daf7;
  color: #000000;
  font-weight: 600;
}

button#four:focus {
  background: #f0f2ff;
  border: 1px solid #d6daf7;
  color: #000000;
  font-weight: 600;
}

button#five:focus {
  background: #f0f2ff;
  border: 1px solid #d6daf7;
  color: #000000;
  font-weight: 600;
}

button#six:focus {
  background: #f0f2ff;
  border: 1px solid #d6daf7;
  color: #000000;
  font-weight: 600;
}

button#seven:focus {
  background: #f0f2ff;
  border: 1px solid #d6daf7;
  color: #000000;
  font-weight: 600;
}

button#eight:focus {
  background: #f0f2ff;
  border: 1px solid #d6daf7;
  color: #000000;
  font-weight: 600;
}

.chip {
  cursor: pointer;
}

.chip.selected,
.chip.selected:hover {
  background-color: #6a2f85;
  color: #fff;
}

.chip:hover {
  background-color: #88797e;
  color: #fff;
}

.chip.disabled:hover {
  background-color: #e4e4e4;
  color: #0009;
  cursor: not-allowed;
}

::-webkit-scrollbar {
  display: none;
}

.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  background-color: #f0f2ff;
  color: #0000008c !important;
  text-align: center;
  padding: 2px;
  border-radius: 3px;
  position: absolute;
  z-index: 1;
  left: -14px;
  bottom: 20px;
  text-align: center;
  width: 50px;
  border: 1px solid #e1e1e1;
  font-size: 11px;
}

.tooltip .tooltiptext.practice {
  left: -16px !important;
  bottom: 20px !important;
}

.tooltip .tooltiptext.video {
  left: -18px !important;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.owl-carousel .owl-item img {
  width: auto !important;
}

.owl-carousel .owl-item {
  width: 170px !important;
}

.owl-item {
  width: 170px !important;
}

.owl-carousel .owl-stage {
  display: flex !important;
}

.owl-carousel.owl-drag .owl-item {
  width: 170px !important;
}

/*.recommendation-box {   cursor : -webkit-grab;   cursor : -moz-grab;   cursor : -o-grab;   cursor : grab; }   .recommendation-box:active {   cursor : -webkit-grabbing;   cursor : -moz-grabbing;   cursor : -o-grabbing;   cursor : grabbing; }*/
.head-box {
  width: 89px;
  height: 28px;
  background: #ffffff;
  border: 1px solid #e3e3e3;
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
  border-radius: 5px;
  padding: 5px 10px;
  font-weight: 600;
  box-sizing: border-box;
  opacity: 0.7;
}

.leaderboard .leader-body {
  max-height: 289px;
  height: 100%;
}

.leaderboard .leader-body.leader-details .leader-top {
  padding: 6px 0px;
  border-bottom: 1px solid #e0e4f0;
}

.leaderboard .leader-body.leader-details .leader-top:first-child {
  padding-top: 0;
}

.leaderboard .leader-body .leader-top {
  padding: 10px 0px;
  border-bottom: 1px solid #e0e4f0;
}

.leaderboard .leader-body .leader-top:first-child {
  padding-top: 0px;
}

.leaderboard .leader-body .leader-top:last-child {
  border: none;
}

.leader-para {
  line-height: 20px;
  margin-right: 15px;
  color: rgb(173, 173, 173) !important;
  width: 50px;
}

.leader-card {
  padding: 16px 15px;
  position: relative;
}

/*.leader-card::after {
      position: absolute;
      content: "";
      height: 1px;
      width: 100%;
      background: #E0E4F0;
      opacity: .5;
      top: 35px;
      left: 0;
  }*/
.owl-theme .owl-nav {
  margin-top: 0 !important;
}

.video .owl-theme .owl-nav [class*="owl-"] {
  background: #642c90 !important;
}

.owl-prev {
  background: #642c90 !important;
}

.parent-req {
  width: 100%;
  height: 203px;
  background: #ffffff;
  box-shadow: 4px 4px 10px rgba(100, 44, 144, 0.06);
  border-radius: 5px;
  padding: 15px;
  margin-top: 15px;
}

.parent-req h1 {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;

  color: #333333;
}

.parent-img {
  position: relative;
  top: -10px;
}

.reward {
  margin-top: 10px;
}

/*How to earn */
.hw-to-earn-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
  padding: 28px 95px;
}

.earn-card {
  height: 270px;
  background: #ffffff;
  border: 1px solid #96cdec;
  border-radius: 4px;
  width: 466px;
}

.earn-card h1 {
  background: #96cdec;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #333333;
  height: 48px;
  padding: 12px 19px;
}

.earn-card h2 {
  position: relative;
}

.earn-card h2::before {
  position: absolute;
  content: "";
  top: -1px;
  left: -21px;
  background: #96cdec;
  border-radius: 0px 3px 3px 0px;
  width: 4px;
  height: 19px;
}

.earn-card .earn-bottom {
  padding: 5px 25px;
  border-top: 1px solid rgb(150, 205, 236);
  width: 100%;
  text-align: right;
  color: #828282;
  font-style: italic;
  font-size: 12px;
}

.earn-card:nth-child(2) {
  border: 1px solid #cfb3f1;
}

.earn-card:nth-child(2) h1 {
  background: #cfb3f1;
}

.earn-card:nth-child(2) .earn-bottom {
  border-top: 1px solid #cfb3f1;
}

.earn-card:nth-child(2) h2::before {
  background: #cfb3f1;
}

.earn-card:nth-child(3) {
  border: 1px solid #71e0d3;
}

.earn-card:nth-child(3) h1 {
  background: #71e0d3;
}

.earn-card:nth-child(3) h2::before {
  background: #71e0d3;
}

.earn-card:nth-child(3) .earn-bottom {
  border-top: 1px solid #71e0d3;
}

.earn-card:nth-child(4) {
  border: 1px solid #f3ce96;
}

.earn-card:nth-child(4) h1 {
  background: #f3ce96;
}

.earn-card:nth-child(4) h2::before {
  background: #f3ce96;
}

.earn-card:nth-child(4) .earn-bottom {
  border-top: 1px solid #f3ce96;
}

.earn-card:nth-child(5) {
  border: 1px solid #f5c1bd;
}

.earn-card:nth-child(5) h1 {
  background: #f5c1bd;
}

.earn-card:nth-child(5) h2::before {
  background: #f5c1bd;
}

.earn-card:nth-child(5) .earn-bottom {
  border-top: 1px solid #f5c1bd;
}

.earn-card:nth-child(6) {
  border: 1px solid #f5bddc;
}

.earn-card:nth-child(6) h1 {
  background: #f5bddc;
}

.earn-card:nth-child(6) h2::before {
  background: #f5bddc;
}

.earn-card:nth-child(6) .earn-bottom {
  border-top: 1px solid #f5bddc;
}

.earn-para {
  position: relative;
  left: 18px;
  color: #666666 !important;
  margin-top: 8px;
}

.earn-para::before {
  position: absolute;
  content: "";
  top: 9px;
  left: -9px;
  background: #4d4a49;
  border-radius: 0px 4px 4px 0px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

.earn-close {
  background-color: #ffffff !important;
  border: 1px solid #e0e4f0;
  border-radius: 3px;
}

.coming-soon-btn {
  width: 133px;
  height: 26px;
  background: #333333;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  padding: 3px 14px 3px 10px;
  justify-content: space-evenly;
  cursor: context-menu;
}

.coming-soon-btn img {
  margin-right: 3px;
}

.videoModal {
  width: 600px;
  height: 340px;
  padding: 12px 11px 5px;
}

.flicking-viewport {
  padding-bottom: 2.5rem !important;
}

.recommend-top .flicking-viewport {
  padding-bottom: 0 !important;
}

.NewMobile {
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
  border-radius: 5px;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  width: 60px;
}
</style>
